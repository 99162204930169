// src/layouts/index.js
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Chrome from '../components/Chrome';
import Header from '../components/Header';

const TemplateWrapper = ({ children }) => (
  <div>
    <Chrome header={<Header />}>
      <Helmet
        title="Interaction Studio | Developers"
        meta={[
          { name: 'description', content: 'Interaction Studio' },
          { name: 'keywords', content: 'developer, code, integration' },
        ]}
      />
      <div>
        <p>{children}</p>
      </div>
    </Chrome>
  </div>
);

TemplateWrapper.propTypes = {
  children: PropTypes.func,
};

export default TemplateWrapper;
